export const palettezm = {
  white: '#FFFFFF',
  black: '#000000',

  gray25: '#4c5983',
  gray15: '#1d3168', //'#202226',
  gray10: '#002951', // old '#141619',
  gray05: '#061a2e', // old '#0b0c0e',

  // new from figma,
  darkLayer0: '#061a2e',
  darkLayer1: '#002951',
  darkLayer2: '#1d3168', // figma used #34343B but a bit too bright

  darkBorder1: '#4c5983',
  darkBorder2: '#7b82a7',

  // Dashboard bg / layer 0 (light theme)
  gray90: '#e8e8e4',
  // Card bg / layer 1
  gray100: '#e8e8e4',
  // divider line
  gray80: '#b6b9d0',
  // from figma
  lightBorder1: '#002951',

  blueDarkMain: '#50accb', // '#4165F5',
  blueDarkText: '#72bbd3', // '#58a6ff', //'#33a2e5', // '#5790FF',
  redDarkMain: '#f62e36',
  redDarkText: '#f7575e',
  greenDarkMain: '#80cc28',
  greenDarkText: '#99d64b',
  orangeDarkMain: '#f68712',
  orangeDarkText: '#f89c35',

  blueLightMain: '#72bbd3',
  blueLightText: '#50accb',
  redLightMain: '#f7575e',
  redLightText: '#f62e36',
  greenLightMain: '#99d64b',
  greenLightText: '#80cc28',
  orangeLightMain: '#f89c35',
  orangeLightText: '#f68712',
};
